// const mockJobUpdates = [];
const mockJobUpdates = [
  {
    action: "Added Job Lead",
    adminName: "Employee Name",
    userName: "Receptionist123",
    companyName: "Company Name",
    date: "1/1/2022",
  },
  {
    action: "Added Job Lead",
    adminName: "Employee Name",
    userName: "Receptionist123",
    companyName: "Company Name",
    date: "1/1/2022",
  },
  {
    action: "Added Job Lead",
    adminName: "Employee Name",
    userName: "Receptionist123",
    companyName: "Company Name",
    date: "1/1/2022",
  },
  {
    action: "Added Job Lead",
    adminName: "AdminName",
    userName: "Receptionist123",
    companyName: "Company Name",
    date: "1/1/2022",
  },
  {
    action: "Added Job Lead",
    adminName: "AdminName",
    userName: "Receptionist123",
    companyName: "Company Name",
    date: "1/1/2022",
  },
  {
    action: "Added Job Lead",
    adminName: "AdminName",
    userName: "Receptionist123",
    companyName: "Company Name",
    date: "1/1/2022",
  },
  {
    action: "Added Job Lead",
    adminName: "AdminName",
    userName: "Receptionist123",
    companyName: "Company Name",
    date: "1/1/2022",
  },
  {
    action: "Added Job Lead",
    adminName: "AdminName",
    userName: "Receptionist123",
    companyName: "Company Name",
    date: "1/1/2022",
  },
  {
    action: "Added Job Lead",
    adminName: "AdminName",
    userName: "Receptionist123",
    companyName: "Company Name",
    date: "1/1/2022",
  },
  {
    action: "Added Job Lead",
    adminName: "AdminName",
    userName: "Receptionist123",
    companyName: "Company Name",
    date: "1/1/2022",
  },
  {
    action: "Added Job Lead",
    adminName: "AdminName",
    userName: "Receptionist123",
    companyName: "Company Name",
    date: "1/1/2022",
  },
  {
    action: "Added Job Lead",
    adminName: "AdminName",
    userName: "Receptionist123",
    companyName: "Company Name",
    date: "1/1/2022",
  },
];

export default mockJobUpdates;
